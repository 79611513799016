

export default function createCampaign() {


    console.log('trying to create a campaign...');
    console.log(phone)
    console.log(list)
    var name = "abcd";
    var phone = "1234";
    var list = "5678";
    var date = "now";
    var url = "";


    const res = fetch('/api')
      .then(response => response.json())
      .then(data => process(data))
      .catch(error => console.error(error));

    let process = (data) => {
        console.log("Data fetched from backend: "  + data);
        var fields = JSON.parse(data).fields;
        name = String(fields.name);
        phone = String(fields.phone);
        list = String(fields.phoneList);   
        date = String(fields.dateTimeFormatted);
        type = String(fields.fileType)
        
        
        const campaignName = name.split(" ").join("_") + "_Campaign"
        console.log(campaignName);
    
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
              },  
            body: JSON.stringify({ 
                campaign: campaignName,
                phone: phone,
                phoneList: list,
                date: date,
                audioUrl: url,
                fileType: type
             })
        };
        fetch('/api/slybroadcast/create-campaign', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
    }



  };


import React from 'react'; 
import createCampaign from './SlyBroadcast.js';

class SuccessPage extends React.Component { 

    componentDidMount() {
        console.log(this.props.globalState);
        createCampaign()
    }
    
    useEffect() {
        console.log(this.props.globalState);
    }


    render() {
        console.log(this.props.globalState);

        return (
            <div>
            <center>Success! Your payment has been processed, and your voicemail will be sent to the 
                phone numbers provided at the time selected.</center>
                <br></br>
            <center>Please reach out to support@carroll.media with any questions!</center>
            </div>
        );
    }
  };
  
  export default SuccessPage;
  

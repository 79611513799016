import React from "react";
import {Form, Button} from 'react-bootstrap';
import createCampaign from "./SlyBroadcast.js";
import DateTimePicker from 'react-datetime-picker'
import { useSelector, useDispatch } from 'react-redux'


export default class SignupForm extends React.Component {
    state = {
        name: "",
        email: "",
        phone: "",
        campaign: "",
        audioFile: null,
        phoneList: ""
    }

  validateForm = (event) => {
    var name = event.target.inputText.value;
    var email = event.target.inputEmail.value;
    var phone = event.target.inputPhone.value;
    var phoneList = event.target.phoneList.value;
    var date = event.target.inputDate.value;
    var time = event.target.inputTime.value;
    var audio = event.target.inputAudio.value;
    
    if (name == "" || email == "" || phone == "" || phoneList == "" || date == "" || time == "" || audio == ""){
        event.preventDefault();
        document.getElementById("error").style.visibility="visible";
        return false;
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    this.setState({[name]: target.value});

    this.props.stateChanger(event);
    }

    handleClick = () => {
        console.log("click handled")
        document.getElementById("myForm").submit();

    }

  render() {
    const style = {
        button: {
          'backgroundColor':'#00DC93'
        },
        text: {
        'backgroundColor':'#080E10',
        'borderColor':'#A1A4A5',
        'color': 'white'
        },
        caption: {
            'color': '#845AFF',
            'fontSize': 'small'
        },
        date: {
            'backgroundColor':'#080E10',
            'color': 'white'
        },
        time: {
            'backgroundColor':'#080E10',
            'color': 'white',
        },
        error: {
            'visibility':'hidden',
            'color':'red'
        }
    }
    return (

    <Form action="api/stripe/create-checkout-session" method="POST" onSubmit={this.validateForm} encType="multipart/form-data">
        <center>
        Please fill out the form below and upload an audio file. The voicemail recording will be sent 
        to the phone numbers listed and show up as a missed call.
        </center>
        <br></br>
     <Form.Group className="mb-3">
      <Form.Label htmlFor="inputName"><sup>*</sup> Your Name:</Form.Label>
        <Form.Control
            style={style.text}
            name="name"
            type="text"
            id="inputText"
            onChange={this.handleInputChange}
        />
    </Form.Group>
    <Form.Group className="mb-3">
        <Form.Label><sup>*</sup> Your Email:</Form.Label>
        <Form.Control
            style={style.text}
            name="email"
            type="email"
            id="inputEmail"
            onChange={this.handleInputChange}
            />

    </Form.Group>
    <Form.Group className="mb-3">
        <Form.Label><sup>*</sup> Phone Number Voicemail is From:</Form.Label>
        <Form.Control
            style={style.text}
            name="phone"
            type="text"
            id="inputPhone"
            // placeholder="+15026774353"
            onChange={this.handleInputChange}
        />
    </Form.Group>
    <Form.Group className="mb-3">
        <Form.Label><sup>*</sup> Phone Number List:</Form.Label>
        <Form.Control
            style={style.text}
            as="textarea" 
            rows={3} 
            name="phoneList"
            onChange={this.handleInputChange}
            >
        </Form.Control>
        <Form.Text 
            style={style.caption}
            id="fileText">
            List each phone number on a new line, making sure there are no empty lines. 
            The text box can be enlarged by dragging the bottom right corner.
        </Form.Text>
    </Form.Group>
    <Form.Group className="mb-3">
        <Form.Label><sup>*</sup> Date and Time to Send Voicemail :</Form.Label>
        <Form.Control
            style={style.date}
            name="date"
            type="date"
            id="inputDate"
            onChange={this.handleInputChange}
        />
        <Form.Text id="fileText">
        </Form.Text>
    </Form.Group>
    <Form.Group className="mb-3">
        <Form.Control
            style={style.time}
            name="time"
            type="time"
            id="inputTime"
            onChange={this.handleInputChange}
        />
        <Form.Text id="fileText">
        </Form.Text>
    </Form.Group>
    {/* <Form action="/api/audiofile/upload" method="post" encType="multipart/form-data" id="myForm"> */}
        <Form.Label><sup>*</sup> Audio File:</Form.Label>
        <Form.Control
            style={style.text}
            name="audioFile"
            type="file"
            id="inputAudio"
            onChange={this.handleInputChange}
        />
        <Form.Text 
            style={style.caption}
            id="fileText">
            Upload audio file (WAV, Mp3 or M4a) with voicemail message
        </Form.Text>
    {/* </Form> */}
    <br/><br/>
    <div id="error" style={style.error}>Please complete all fields!</div>
    <Button
        style={style.button}
        variant="custom"
        type="submit">
            Checkout
        </Button>
    </Form>
    
    )
}

}




